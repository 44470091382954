import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';

import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import RestoreForm from './components/RestoreForm';

class Main extends Component {
  render() {
    return (
      <div id="auth-controls">
        <div className="auth__blur" />
        <BrowserRouter>
          <Switch>
            <Route exact path="/auth/login" component={LoginForm} />
            <Route exact path="/auth/register" component={RegisterForm} />
            <Route exact path="/auth/restore" component={RestoreForm} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

Main.propTypes = {};

export default Main;
