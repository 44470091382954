import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { translate } from 'react-switch-lang';

class LoginLink extends Component {
  render() {
    const { t } = this.props;
    return (
      <NavLink to="/auth/login" className="auth-loginLink">
        {t('Sign In')}
      </NavLink>
    );
  }
}

LoginLink.propTypes = {};

export default translate(LoginLink);
