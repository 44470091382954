import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { translate } from 'react-switch-lang';

class RegisterLink extends Component {
  render() {
    const { t } = this.props;
    return (
      <NavLink to="/auth/register" className="auth-registerLink">
        {t('login.labels.Sign Up')}
      </NavLink>
    );
  }
}

RegisterLink.propTypes = {};

export default translate(RegisterLink);
