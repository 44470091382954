import React, { useState } from 'react';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate, getLanguage,
} from 'react-switch-lang';
import {
  ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import merge from 'lodash/merge';
import { requestGet, requestPost } from './real.backend';
import en from '../auth/i18n/en.json';
import ru from '../auth/i18n/ru.json';
import tr from '../auth/i18n/tr.json';
import uk from '../auth/i18n/uk.json';

export const initTranslations = (langs) => {
  setTranslations({
    ...langs,
  });
  setDefaultLanguage('en');
  const userLangs = navigator.languages;// navigator.language || navigator.userLanguage;
  if (userLangs.indexOf('tr') >= 0) setLanguage('tr');
  else if (userLangs.indexOf('uk') >= 0) setLanguage('uk');
  else if (userLangs.indexOf('ru') >= 0) setLanguage('ru');
  else setLanguage('en');
  setLanguageCookie('language', {}, 'language');
};

export const loadTranslations = () => requestGet('/translations/languages.json').then((langs) => {
  const intLangs = {
    en,
    ru,
    tr,
    uk,
  };
  Promise.all(langs.map((lang) => requestGet(`/translations/${lang}.json`)))
    .then((result) => {
      const languages = {};
      result.map((r) => languages[r.lang] = merge(intLangs[r.lang], r));
      initTranslations(languages);
      return true;
    });
});

const Language = () => {
  const handleSetLanguage = (e, key) => {
    e.stopPropagation();
    e.preventDefault();
    setLanguage(key);
  };
  const [dropdownOpen, setOpen] = useState(false);
  return (
    <>
      <div className="language-dropdown">
        <ButtonDropdown isOpen={dropdownOpen} toggle={() => setOpen(!dropdownOpen)} className="language-dropdown p-20">
          <DropdownToggle caret>
            {getLanguage().toUpperCase()}
          </DropdownToggle>
          <DropdownMenu className="language-dropdownMenu">
            <DropdownItem onClick={(e) => handleSetLanguage(e, 'en')}>
              <a href="#javascript" data-lng="en">
                <i className="flag-icon flag-icon-us" />
                {' '}
                EN
              </a>
            </DropdownItem>
            <DropdownItem onClick={(e) => handleSetLanguage(e, 'ru')}>
              <a href="#javascript" data-lng="ru">
                <i className="flag-icon flag-icon-ru" />
                {' '}
                RU
              </a>
            </DropdownItem>
            <DropdownItem onClick={(e) => handleSetLanguage(e, 'uk')}>
              <a href="#javascript" data-lng="ua">
                <i className="flag-icon flag-icon-ua" />
                {' '}
                UA
              </a>
            </DropdownItem>
            <DropdownItem onClick={(e) => handleSetLanguage(e, 'tr')}>
              <a href="#javascript" data-lng="ua">
                <i className="flag-icon flag-icon-tr" />
                {' '}
                TR
              </a>
            </DropdownItem>
            <DropdownItem divider />
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    </>
  );
};

export default translate(Language);

export const saveTranslate = (key, translates) => {
  requestPost('/api/translations/saveKey', {
    key,
    translates,
  })
    .then((resp) => console.log(resp));
};
