import { toast } from 'react-toastify';
import { t } from 'react-switch-lang';

export const configureFakeBackend = () => {

};

export function handleResponse(url, requestOptions, withToast = true) {
  return new Promise((resolve, reject) => {
    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          switch (response.status) {
            case 401:
              // if (withToast) toast.error(t('network.Need authentification'));
              // window.location.reload();
              return response.json();
              break;
            case 403:
              if (withToast) toast.error(t('network.Forbidden'));
              return response.json();
              break;
            case 422:
              // if (withToast) toast.error(t('Wrong data'));
              return response.json();
              break;
            case 404:
              if (withToast) toast.error(t('network.Resourse not found'));
              reject(new Error(t('network.Resourse not found')));
              break;
            default:
              if (withToast) toast.error(t('network.Unknown error'));
              reject(new Error(t('network.Unknown error')));
          }
        } else {
          const json = response.json();
          return json;
        }
      })
      .then((json) => {
        if (json && json.token) {
          localStorage.setItem('zakaz_token', json.token);
        }
        resolve(json);
      })
      .catch((error) => reject(error));
  });
}

function getCookie(name) {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function authHeader() {
  // return authorization header with jwt token
  let jwtToken = localStorage.getItem('zakaz_token');
  if (!jwtToken) {
    jwtToken = getCookie('jwt');
  }
  if (jwtToken) {
    return { Authorization: `Bearer ${jwtToken}` };
  }
  return {};
}

export function mergeFetchOptions(options, headers = {}) {
  const authHeaders = authHeader();
  return {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...authHeaders,
      ...headers,
    },
    ...options,
  };
}

export function requestGet(url, options = {}, withToast = true) {
  const requestOptions = mergeFetchOptions(options);
  requestOptions.method = 'GET';
  return handleResponse(url, requestOptions, withToast);
}

export function requestPost(url, data = {}, options = {}) {
  const requestOptions = mergeFetchOptions(options);
  requestOptions.method = 'POST';
  requestOptions.body = JSON.stringify(data);
  return handleResponse(url, requestOptions);
}

export function uploadForm(url, formData = null, options = {}) {
  const requestOptions = mergeFetchOptions(options, {
    // 'Content-Type': 'multipart/form-data',
    'Content-Type': 'application/x-www-form-urlencoded',
  });
  delete (requestOptions.headers['Content-Type']);
  requestOptions.method = 'POST';
  requestOptions.body = formData;
  return handleResponse(url, requestOptions);
}

export function requestDelete(url, data = {}, options = {}) {
  const requestOptions = mergeFetchOptions(options);
  requestOptions.method = 'DELETE';
  requestOptions.body = JSON.stringify(data);
  return handleResponse(url, requestOptions);
}

export function requestPut(url, data = {}, options = {}) {
  const requestOptions = mergeFetchOptions(options);
  requestOptions.method = 'PUT';
  requestOptions.body = JSON.stringify(data);
  return handleResponse(url, requestOptions);
}
