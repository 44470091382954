/* eslint-disable no-extra-boolean-cast */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {translate} from 'react-switch-lang';
import RegisterLink from './RegisterLink';
import RestoreLink from './RestoreLink';
import authStorage from '../storages/authStorage';
import Form from "./Form";

class LoginForm extends Component {
  render() {
    const {t} = this.props;
    return (
      <Form t={t} title={t("Sign In")}>
        <div className="card-body">
          <input
            type="text"
            className={`form-control`}
            placeholder={t('login.placeholders.username')}
            value={authStorage.login.email}
            onInput={(e) => authStorage.changeLoginField('email', e.target.value)}
          />
          <input
            type="password"
            className={`form-control`}
            placeholder={t('login.placeholders.password')}
            value={authStorage.login.password}
            onInput={(e) => authStorage.changeLoginField('password', e.target.value)}
          />
        </div>
        <div className="card-footer">
          <div className="login__actions">
            <button
              className="btn float-right login__btn"
              onClick={authStorage.attemptLogin}
              disabled={!!authStorage.readyToLogin}
            >
              {t('login.labels.Login')}
            </button>
          </div>
          <div className="d-flex justify-content-center links">
            {t('login.labels.Don\'t have an account?')}&nbsp;
            <RegisterLink/>
          </div>
          <div className="d-flex justify-content-center">
            <RestoreLink/>
          </div>
        </div>
        {authStorage.allowSocials && <div className="auth__icons">
          <span><i className="fab fa-2x fa-facebook-square"/></span>
          <span><i className="fab fa-2x fa-google"/></span>
          {/* <span><i className="fab fa-twitter-square" /></span> */}
        </div>
        }
      </Form>
    );
  }
}

LoginForm.propTypes = {};

export default translate(observer(LoginForm));
