import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { translate } from 'react-switch-lang';

class RestoreLink extends Component {
  render() {
    const { t } = this.props;
    return (
      <NavLink to="/auth/restore" className="auth-restoreLink">
        {t('login.labels.Forgot your password?')}
      </NavLink>
    );
  }
}

RestoreLink.propTypes = {};

export default translate(RestoreLink);
