import React from 'react';

function Form({ children, title }) {
  return (
    <div className="login__form">
      <div className="login__header">{title}</div>
      {children}
    </div>
  );
}

export default Form;
