import validate from 'validate.js';
import { toast } from 'react-toastify';
import { requestPost } from '../../shared/real.backend';

const { makeAutoObservable } = require('mobx');

class AuthStorage {
  login = {
    email: '',
    password: '',
    remember: false,
  };

  register = {
    name: '',
    email: '',
    phone: '',
    password: '',
    password_confirmation: '',
  };

  allowSocials = false;

  get readyToLogin() {
    const alow = true;
    const validateResult = validate(this.login, {
      email: {
        format: {
          pattern: '\\+\\d{12,12}',
          flags: 'i',
          message: 'incorrect phone',
        },
        length: { minimum: 12 },
      },
      password: {
        length: { minimum: 8 },
      },
    });
    return validateResult;
  }

  get readyToRegister() {
    const alow = true;
    const validateResult = validate(this.register, {
      phone: {
        format: {
          pattern: '\\+\\d{12,12}',
          flags: 'i',
          message: 'incorrect phone',
        },
        length: { minimum: 12 },
      },
      password: {
        length: { minimum: 8 },
      },
      name: { length: { minimum: 3 } },
      password_confirmation: { length: { minimum: 8 }, equality: 'password' },
    });
    return validateResult;
  }

  constructor() {
    makeAutoObservable(this);
  }

  changeLoginField = (field, value) => {
    if (this.login[field] !== undefined) {
      this.login[field] = value;
    }
  };

  attemptLogin = () => {
    requestPost('/api/login', {
      ...this.login,
    })
      .then((resp) => {
        const { status } = resp;
        console.log(resp);
        if (status === 'fail') {
          toast.error(resp.message);
        } else
        if (status === 'success') {
          const { user, roles } = resp;
          console.log('WREST', JSON.stringify(roles));
          if (roles.includes('admin') || roles.includes('manager')) {
            // window.location.href = '/shop';
            window.location.href = '/application';
          } else {
            // window.location.href = '/application';
            window.location.href = '/shop';
          }
        } else {
          toast.alert('Something wrong');
        }
      });
  };

  changeRegisterField = (field, value) => {
    if (this.register[field] !== undefined) {
      this.register[field] = value;
    }
  };

  attemptRegister = () => {
    requestPost('/api/register', {
      ...this.register,
    })
      .then((resp) => {
        const { status } = resp;
        if (status === 'fail') {
          toast.error(resp.message);
        } else if (resp.errors) {
          Object.keys(resp.errors).map((field) => {
            toast.error(resp.errors[field].join(', '));
          });
        } else {
          toast.success(resp.message);
          window.location.href = '/auth/login';
        }
      });
  };
}

const authStorage = new AuthStorage();

export default authStorage;
