import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { translate } from 'react-switch-lang';
import { observer } from 'mobx-react';
import authStorage from '../storages/authStorage';
import RestoreLink from './RestoreLink';
import LoginLink from './LoginLink';
import Form from "./Form";

class RegisterForm extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Form title={t('Sign Up')}>
          <div className="card-body">
              <input
                type="text"
                className={`form-control${validate(authStorage.register, { name: { length: { minimum: 3 } } }) ? ' alert-danger' : ''}`}
                placeholder={t('login.placeholders.name')}
                value={authStorage.register.name}
                onInput={(e) => authStorage.changeRegisterField('name', e.target.value)}
              />
              <input
                type="text"
                className={`form-control`}
                placeholder={t('login.placeholders.username')}
                value={authStorage.register.phone}
                onInput={(e) => authStorage.changeRegisterField('phone', e.target.value)}
              />
              <input
                type="text"
                className={`form-control`}
                placeholder={t('login.placeholders.email')}
                value={authStorage.register.email}
                onInput={(e) => authStorage.changeRegisterField('email', e.target.value)}
              />
              <input
                type="password"
                className={`form-control${validate(authStorage.register, { password: { length: { minimum: 8 } } }) ? ' alert-danger' : ''}`}
                placeholder={t('login.placeholders.password')}
                value={authStorage.register.password}
                onInput={(e) => authStorage.changeRegisterField('password', e.target.value)}
              />
              <input
                type="password"
                className={`form-control${validate(authStorage.register, { password_confirmation: { length: { minimum: 8 }, equality: 'password' } }) ? ' alert-danger' : ''}`}
                placeholder={t('login.placeholders.password_confirmation')}
                value={authStorage.register.password_confirmation}
                onInput={(e) => authStorage.changeRegisterField('password_confirmation', e.target.value)}
              />
          </div>
          <div className="card-footer">
            <div className="login__actions">
            <button
              className="btn float-right login__btn"
              onClick={authStorage.attemptRegister}
              disabled={!!authStorage.readyToRegister}
            >
              {t('login.labels.Sign Up')}
            </button>
            </div>

            <div className="d-flex justify-content-center links">
              {t('login.labels.Have an account?')} &nbsp;
              <LoginLink />
            </div>
            <div className="d-flex justify-content-center">
              <RestoreLink />
            </div>
          </div>
      </Form>
      </>
    );
  }
}

RegisterForm.propTypes = {};

export default translate(observer(RegisterForm));
